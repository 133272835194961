import React from 'react';
import SEO from "../../components/seo"
import Layout from "../../components/layout"

import Grid from '@material-ui/core/Grid';
import Mission from '../../components/Mission'
import ServicesMenu from '../../components/ServicesMenu'
import SliderBootstrapStatic from '../../components/SliderBootstrapStatic';

export default function Pharmacovigilance(){

    return(
        <Layout>
            <SEO 
                title="Pharmacovigilance"
                description="Savante Consulting Limited is able to serve as local Pharmacovigilance Agent for products in Nigeria and Kenya. We will generate necessary documentation, including ICSR and PSUR to support continuing registration of the product in the local market."
            />
            <br /><br />
            <SliderBootstrapStatic title="Pharmacovigilance" />

            <div className="container">
                <br />
                <Grid container spacing={3}>
                        <Grid item xs={12} md={8} lg={8}>
                            <ServicesMenu />
                            <h5>Pharmacovigilance</h5>
                            <p className="pr-20 justify" style={{textAlign: 'justify'}}>
                                Savante Consulting Limited is able to serve as local Pharmacovigilance Agent for products in Nigeria and Kenya. We will generate necessary documentation, including ICSR and PSUR to support continuing registration of the product in the local market. Savante will provide a call desk and data management support system that meets the requirements of Pharmacovigilance guidelines of the regulatory authorities.
                            </p>
                        </Grid>
                        <Grid item xs={12} md={4} lg={4}>  
                                <Mission />
                        </Grid>
                    </Grid>
                    <br /> <br />
            </div>
        </Layout>
    );
}